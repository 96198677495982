import React from 'react'
import { IoMdPersonAdd } from "react-icons/io";
import { IoPersonRemoveSharp } from "react-icons/io5";
import { MdOutlineAutorenew } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";

const Dashbaord = ({data}) => {
    console.log("data", data)

    // console.log("daybfore",data.totalData.totalrevenuedaybefore[0].total_revenue )
    return (
        <div>
            <div class="p-4 sm:ml-64 ">
                <div class="p-4 md:p-0 rounded-lg dark:border-gray-700 mt-14">
                    {/* <p className='text-xl mt-2 font-bold text-[#030522] '>TODAY</p> */}
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-5 md:mt-2 ">
                        <div className="flex flex-col items-center border gap-3 justify-center h-24 md:h-40 rounded" style={{ backgroundImage: 'linear-gradient(to right, #5DA9FF, #0873F0)' }}>
                           <div className='flex flex-row items-center justify-cente gap-3'>
                           <IoMdPersonAdd color='white' size={30}/>
                            <p className="md:text-4xl text-2xl font-bold text-gray-100 dark:text-gray-500">
                               {data ? data.totalData.totalSub :''}
                            </p>
                           </div>
                           <div>
                            <p className='text-white font-bold text-md md:text-2xl'>NEW SUB</p>
                           </div>
                        
                            
                        </div>


                        {/* 17C6EE , 0873F0*/}
                        <div className="flex flex-col items-center border gap-3 justify-center h-24 md:h-40 rounded" style={{ backgroundImage: 'linear-gradient(to right, #41E297, #3BB4B6)' }}>
                        
                        <div className='flex flex-row items-center justify-cente gap-3'>
                          <IoPersonRemoveSharp size={30} color='white'/>
                            <p class="md:text-4xl font-bold text-2xl text-gray-100 dark:text-gray-500">
                         {data ? data.totalData.totalUnsub : ""}
                            </p>
                            </div>
                            <div>
                            <p className='text-white font-bold text-md md:text-2xl'> UNSUB</p>
                           </div>
                            
                        </div>
                        <div className="flex flex-col items-center border justify-center h-24 md:h-40 gap-3 rounded" style={{ backgroundImage: 'linear-gradient(to right, #FFDA3B, #FF8857)' }}>
                        <div className='flex flex-row items-center justify-cente gap-3'>

                           <MdOutlineAutorenew size={30} color='white'/>
                            <p class="md:text-4xl text-2xl font-bold text-gray-100 dark:text-gray-500">
                            {data ? data.totalData.totalRen : ''}
                            </p>
                          </div>
                          <div>
                            <p className='text-white font-bold text-md md:text-2xl'> RENEW</p>
                           </div>
                        </div>
                        <div className="flex flex-col items-center border gap-3 justify-center h-24 md:h-40 rounded" style={{ backgroundImage: 'linear-gradient(to right, #A05AF7, #5730C0)' }}>
                        <div className='flex flex-row items-center justify-cente gap-3'>
                     <BsGraphUpArrow color='white' size={27}/>
                            <p class="md:text-4xl text-2xl font-bold text-gray-100 dark:text-gray-500">
                          {data ? data.totalData.totalAmount.dailyRevenue : ''}
                            </p>
                        </div>
                        <div>
                            <p className='text-white font-bold text-md md:text-2xl'> DAILY REVENUE</p>
                           </div>
                           </div>

                    </div>




                    {/* <div class="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
                        <p class="text-2xl text-gray-400 dark:text-gray-500">
                            <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                            </svg>
                        </p>
                    </div>
                    <div class="grid grid-cols-2 gap-4 mb-4">
                        <div class="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                            <p class="text-2xl text-gray-400 dark:text-gray-500">
                                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                            </p>
                        </div>
                        <div class="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                            <p class="text-2xl text-gray-400 dark:text-gray-500">
                                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                            </p>
                        </div>
                        <div class="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                            <p class="text-2xl text-gray-400 dark:text-gray-500">
                                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                            </p>
                        </div>
                        <div class="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                            <p class="text-2xl text-gray-400 dark:text-gray-500">
                                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                            </p>
                        </div>
                    </div>
                    <div class="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
                        <p class="text-2xl text-gray-400 dark:text-gray-500">
                            <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                            </svg>
                        </p>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div class="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                            <p class="text-2xl text-gray-400 dark:text-gray-500">
                                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                            </p>
                        </div>
                        <div class="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                            <p class="text-2xl text-gray-400 dark:text-gray-500">
                                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                            </p>
                        </div>
                        <div class="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                            <p class="text-2xl text-gray-400 dark:text-gray-500">
                                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                            </p>
                        </div>
                        <div class="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                            <p class="text-2xl text-gray-400 dark:text-gray-500">
                                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Dashbaord
