import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'; // Import moment

const Revenue = () => {
  const [data, setData] = useState([]);
  const currentDate = moment(); // Use moment for current date
  const firstDayOfMonth = moment().startOf('month'); // Get first day of the current month
  const lastDayOfMonth = moment().endOf('month'); // Get last day of the current month

  const [startDate, setStartDate] = useState(firstDayOfMonth.format('YYYY-MM-DD')); // Format date for input
  const [endDate, setEndDate] = useState(lastDayOfMonth.format('YYYY-MM-DD')); // Format date for input
  
  const url = "/totalRevenue/totaldata";

  const fetchData = async () => {
    const requestData = {
      start: startDate,
      to: endDate
    };

    console.log(
      "data",requestData
    )
    try {
      const response = await axios.post(url, requestData);
      console.log("response=============",response.data)
      // Reverse the data array to display it in descending order
      setData(response.data.totalData.reverse());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const exportToExcel = () => {
    const fileName = 'revenue_data.xlsx';
    const worksheet = XLSX.utils.json_to_sheet(data.map(row => ({
      'DATE': row.date,
      'NEW SUB': row.total_sub,
      'REN': row.total_ren,
      'UNSUB': row.total_unsub,
      'SUBSCRIPTION REVENUE': row.total_sub_revenue,
      'RENEWAL REVENUE': row.total_ren_revenue,
      'DAILY REVENUE': row.daily_revenue,
      'TOTAL REVENUE': row.total_revenue
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Revenue Data');
    XLSX.writeFile(workbook, fileName);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-2 sm:ml-64 mt-[-25px] md:mt-0 bg-[#F9FAFB]">
      <div className="p-2 mt-[20px] md:p-0 rounded-lg dark:border-gray-700 ">
        <div className='flex flex-col justify-center md:justify-between md:flex md:flex-row  mb-5 mx-5'>
          <div className="flex items-center">
            <DatePicker
              selected={moment(startDate).toDate()} // Convert to JavaScript Date object
              onChange={date => setStartDate(moment(date).format('YYYY-MM-DD'))} // Convert to moment and format
              selectsStart
              startDate={moment(startDate).toDate()} // Convert to JavaScript Date object
              endDate={moment(endDate).toDate()} // Convert to JavaScript Date object
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholderText="Select start date"
            />
            <span className="mx-4 text-gray-500">to</span>
            <DatePicker
              selected={moment(endDate).toDate()} // Convert to JavaScript Date object
              onChange={date => setEndDate(moment(date).format('YYYY-MM-DD'))} // Convert to moment and format
              selectsEnd
              startDate={moment(startDate).toDate()} // Convert to JavaScript Date object
              endDate={moment(endDate).toDate()} // Convert to JavaScript Date object
              minDate={moment(startDate).toDate()} // Convert to JavaScript Date object
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholderText="Select end date"
            />
            <button onClick={fetchData} className="ml-4 px-4 py-2 font-bold bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">Check</button>
          </div>

          <div className='mx-auto md:mt-0 mt-5 md:flex md:mx-5'>
            <button onClick={exportToExcel} className="text-white font-bold bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Download Report</button>
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-100 uppercase bg-gray-900 dark:bg-gray-700 dark:text-gray-400">
              <tr className='text-center'>
                <th scope="col" className="px-6 py-3">
                  DATE
                </th>
                <th scope="col" className="px-6 py-3">
                  NEW SUB
                </th>
                <th scope="col" className="px-6 py-3">
                  REN
                </th>
                <th scope="col" className="px-6 py-3">
                  UNSUB
                </th>
                <th scope="col" className="px-6 py-3">
                  SUBSCRIPTION REVENUE
                </th>
                <th scope="col" className="px-6 py-3">
                  RENEWAL REVENUE
                </th>
                <th scope="col" className="px-6 py-3">
                  DAILY REVENUE
                </th>
                <th scope="col" className="px-6 py-3">
                  TOTAL REVENUE
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{row.date}</td>
                  <td className="px-6 py-4 text-gray-900">{row.total_sub}</td>
                  <td className="px-6 py-4 text-gray-900">{row.total_ren}</td>
                  <td className="px-6 py-4 text-gray-900">{row.total_unsub}</td>
                  <td className="px-6 py-4 text-gray-900">{row.total_sub_revenue}</td>
                  <td className="px-6 py-4 text-gray-900">{row.total_ren_revenue}</td>
                  <td className="px-6 py-4 text-gray-900">{row.daily_revenue}</td>
                  <td className="px-6 py-4 text-gray-900">{row.total_revenue}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Revenue;
