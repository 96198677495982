import React, { useState } from "react";
import axios from 'axios';
import "../css/Login.css"; 
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

function App() {
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/login', {
                user: user,
                pass: pass
            });
            console.log(response.data); 
            
            if(response.data.status == '1'){
                toast.success('Login Successfull');
            
                Cookies.set('user', user);
                Cookies.set('pass', pass);
                
                setTimeout(() => {
                    navigate('/');
                }, 3000);
               
            } else {
                toast.error("Invalid credentials");
                setPass('');
                setUser('');
                navigate('/login');
            }
          
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="bg-[#844AE2] h-screen">
            <div className="p-4 md:p-0">
            <div className="container flex justify-center mx-auto py-[100px]">
                <div class="w-full max-w-sm p-4 bg-white border  border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <form onSubmit={handleSubmit} class="space-y-6">
                        <h5 class="text-3xl font-medium text-gray-900 text-center dark:text-white">Login to Dashboard</h5>
                        <div>
                            <label for="user" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User Name</label>
                            <input type="text" name="user" id="user" value={user} onChange={(e) => setUser(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="" required />
                        </div>
                        <div>
                            <label for="pass" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User Password</label>
                            <input type="password" name="pass" id="pass" value={pass} onChange={(e) => setPass(e.target.value)} placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                        </div>
                        <button type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login </button>
                    </form>
                </div>
            </div>

            </div>
            <ToastContainer/>
        </div>
        
    );
}

export default App;
