import React, { useEffect, useState,useRef } from 'react'
import Navbar from '../Components/Navbar'
import axios from 'axios'
import Dashbaord from '../Components/Dashbaord';
import Revenue from '../Components/Revenue';
import {Link,useNavigate} from 'react-router-dom'
import { RxAvatar } from "react-icons/rx";
import Cookies from 'js-cookie';
import ServiceBasedRevenue from '../Components/ServiceBasedRevenue';
const Home = () => {

  const [data, setData] = useState();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [selectedService, setSelectedService] = useState('All');
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility
  const url = '/totalRevenue';
  const dropdownRef = useRef(null); // Reference to the dropdown content

  const usercheck = () => {
    const user = Cookies.get('user');
    const pass = Cookies.get('pass')

    if (!user || !pass || user !== 'admin' || pass !== 'ngAdmin@1234') {
      navigate('/login');
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(url);
      console.log('response', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    usercheck();
    fetchData();
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const dashobaordtoggle = () => {
    setSidebarOpen(!sidebarOpen);
    navigate('/');
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  const logout = () => {
    Cookies.remove('user');
    Cookies.remove('pass');
    navigate('/login');
  };

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleServiceSelect = (service) => {
    setSelectedService(service);
    setDropdownOpen(false); // Close the dropdown after selection
  };

      
  return (
    <div>
     <div>
     <nav className="fixed top-0 z-50 w-full bg-[#030522] border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
          <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-start rtl:justify-end">
                <button
                  onClick={toggleSidebar}
                  data-drawer-target="logo-sidebar"
                  data-drawer-toggle="logo-sidebar"
                  aria-controls="logo-sidebar"
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                >
                  <span className="sr-only">Open sidebar</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                  </svg>
                </button>
                <a className="flex ms-2 md:me-24">
                  <h1 className="text-3xl font-bold text-white">NDOTO DASHBOARD</h1>
                </a>
              </div>

              <div className="flex items-center">
                <div className="flex items-center ms-3 relative">
                  <button
                    onClick={toggleUserMenu}
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full  dark:focus:ring-gray-600"
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                  >
                    <span className="sr-only">Open user menu</span>
                    <RxAvatar color='white' size={30} />
                  </button>
                  {userMenuOpen && (
                    <div
                      className="z-50 absolute right-0 top-10 py-1 w-48 bg-white rounded-md shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                      id="dropdown-user"
                    >
                      <div className="px-4 py-3" role="none">
                        <p className="text-sm text-gray-900  font-bold uppercase
                         dark:text-white" role="none">
                      {Cookies.get('user')}
                        </p>
                       
                      </div>
                      <ul className="py-1" role="none">
                
                        <li onClick={logout}>
                          <a  className="block uppercase px-4  font-bold py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">
                            Sign out
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>


            <aside
          id="logo-sidebar"
          className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${
            sidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}
          aria-label="Sidebar"
        > 
                <div class="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
                    <ul class="space-y-2 mt-5 md:mt-2 font-medium">

                        <li onClick={dashobaordtoggle} >
                          
                            <a  class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                                    <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                                    <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                                </svg>
                                <span class="ms-3 ">Dashboard</span>
                            </a>
                           
                          
                        </li>
                      
                      
                        <li onClick={logout}>
                            <a class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3" />
                                </svg>
                                <span class="flex-1 ms-3 whitespace-nowrap">Log Out</span>
                            </a>
                        </li>
                       
                    </ul>
                </div>
            </aside>

<Dashbaord data={data}/>
<div className="z-50 relative mt-[-40px] md:mt-0">
        <div className="flex justify-center max-w-md mx-auto">
          <div className="p-2 sm:ml-64 relative">
            <div className="p-2 mt-[20px] md:p-0 rounded-lg dark:border-gray-700 ">
              <div>
                <button
                  onClick={toggleDropdown}
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                >
                SELECT SERVICE
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                {dropdownOpen && ( // Render dropdown content only if open
                  <div
                    ref={dropdownRef}
                    className="origin-top-right absolute mt-2 w-44 bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 z-50"
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <li>
                        <button
                          onClick={() => handleServiceSelect('All')}
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          All
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleServiceSelect('N-WORLD BEAUTY TV')}
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          N-WORLD BEAUTY TV
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleServiceSelect('N-WORLD EDUCATION')}
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          N-WORLD EDUCATION
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleServiceSelect('N-WORLD WOH')}
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          N-WORLD WOH
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Render Revenue or ServiceBasedRevenue based on selectedService */}
      {selectedService === 'All' ? <Revenue /> : <ServiceBasedRevenue selectedService={selectedService} />}


           


        </div>
    </div>
  )
}

export default Home
